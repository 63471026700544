import Dashboard from "@/pages/Dashboard.vue";

export default [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
];
