export default [
  {
    path: "/review-soal/belum-review",
    component: () => import("@/pages/review-questions/Unreview.vue"),
  },
  {
    path: "/review-soal/belum-review/detail/:id",
    component: () => import("@/pages/questions/Detail.vue"),
  },
  {
    path: "/review-soal/sudah-review",
    component: () => import("@/pages/review-questions/Reviewed.vue"),
  },
  {
    path: "/review-soal/belum-review/review/:id",
    component: () => import("@/pages/review-questions/ReviewQuestion.vue"),
  },
  {
    path: "/review-soal/sudah-review/detail/:id",
    component: () => import("@/pages/review-questions/DetailReview.vue"),
  },
];
