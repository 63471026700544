export default [
  {
    path: "/soal/buat-soal",
    component: () => import("@/pages/questions/Form.vue"),
  },
  {
    path: "/soal/detail/:id",
    component: () => import("@/pages/questions/Detail.vue"),
  },
  {
    path: "/soal/belum-submit",
    component: () => import("@/pages/questions/Created.vue"),
  },
  {
    path: "/soal/belum-submit/update/:id",
    component: () => import("@/pages/questions/Update.vue"),
  },
  {
    path: "/soal/belum-submit/detail/:id",
    component: () => import("@/pages/questions/Detail.vue"),
  },
  {
    path: "/soal/sudah-submit",
    component: () => import("@/pages/questions/Submitted.vue"),
  },
  {
    path: "/soal/sudah-submit/detail/:id",
    component: () => import("@/pages/questions/Detail.vue"),
  },
  {
    path: "/paket-soal/list-paket",
    component: () => import("@/pages/question-packages/List.vue"),
  },
  {
    path: "/paket-soal/buat-paket",
    component: () => import("@/pages/question-packages/Form.vue"),
  },
];
