<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-laptop", title: "DASHBOARD" },
  {
    path: "/soal",
    icon: "fa fa-list",
    title: "SOAL",
    children: [
      { path: "/soal/buat-soal", title: "BUAT SOAL" },
      { path: "/soal/belum-submit", title: "BELUM SUBMIT" },
      { path: "/soal/sudah-submit", title: "SUDAH SUBMIT" },
    ],
  },
  {
    path: "/review",
    icon: "fa fa-file-alt",
    title: "REVIEW SOAL",
    children: [
      { path: "/review-soal/belum-review", title: "BELUM REVIEW" },
      { path: "/review-soal/sudah-review", title: "SUDAH REVIEW" },
    ],
  },
  {
    path: "/paket-soal",
    icon: "fa fa-book",
    title: "PAKET SOAL",
    children: [
      { path: "/paket-soal/buat-paket", title: "BUAT PAKET" },
      { path: "/paket-soal/list-paket", title: "LIST PAKET" },
    ],
  },
  // {
  //   path: "/akademisi",
  //   icon: "fa fa-users",
  //   title: "AKADEMISI",
  //   children: [
  //     { path: "/akademisi/dosen", title: "DOSEN" },
  //     { path: "/akademisi/mahasiswa", title: "MAHASISWA" },
  //   ],
  // },
  {
    path: "/master",
    icon: "fa fa-archive",
    title: "MASTER DATA",
    children: [
      { path: "/master/blok-mk", title: "BLOK MK" },
      { path: "/master/bidang-ilmu", title: "BIDANG ILMU" },
      { path: "/master/topik", title: "TOPIK" },
      // { path: "/master/tinjauan", title: "TINJAUAN" },
      // { path: "/master/sub-tinjauan", title: "SUB TINJAUAN" },
    ],
  },
  // {
  //   path: "/pengumuman",
  //   icon: "fa fa-bullhorn",
  //   title: "PENGUMUMAN",
  // },
  {
    path: "/pengguna",
    icon: "fa fa-user",
    title: "USER MANAGEMENT",
  },
];

export default sidebarMenu;
</script>
