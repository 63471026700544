var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row d-none"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-blue"},[_vm._m(1),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH MAHASISWA AKTIF")]),_c('p',[_vm._v(_vm._s(_vm.j_soal))])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-green"},[_vm._m(2),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH DOSEN")]),_c('p',[_vm._v(_vm._s(_vm.j_review))])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-info"},[_vm._m(3),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH SOAL")]),_c('p',[_vm._v(_vm._s(_vm.j_soal))])])])]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-lime"},[_vm._m(4),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH SUDAH DIREVIEW")]),_c('p',[_vm._v(_vm._s(_vm.j_review))])])])]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-warning"},[_vm._m(5),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH BELUM DIREVIEW")]),_c('p',[_vm._v(_vm._s(_vm.j_unreview))])])])]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"widget widget-stats bg-danger"},[_vm._m(6),_c('div',{staticClass:"stats-info"},[_c('h4',[_vm._v("JUMLAH PAKET SOAL")]),_c('p',[_vm._v(_vm._s(_vm.j_paket))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"page-header"},[_vm._v("Dashboard "),_c('small')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-users"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-users"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-list-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-tasks"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-list"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-icon"},[_c('i',{staticClass:"fa fa-book"})])
}]

export { render, staticRenderFns }