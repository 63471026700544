import { jwtDecode } from "jwt-decode";

const userData = {
  data() {
    return {
      userData: null,
      userFullName: null,
      userName: null,
      userEmail: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const userData = localStorage.getItem("userData");
    if (userData) {
      this.userData = JSON.parse(userData);
      this.userFullName = this.userData.nama;
      const decodedToken = jwtDecode(this.userData.token);
      this.userName = decodedToken.username;
      this.userLevel = decodedToken.role;
      this.userAccess = decodedToken.access_rights;
      this.userToken = this.userData.token;
      // this.userPhoto = this.userData.foto;
      // console.log(decodedToken);
    }
  },
};

export default userData;
