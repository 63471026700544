export default [
  {
    path: "/master/blok-mk",
    component: () => import("@/pages/masters/blocks/List.vue"),
  },
  {
    path: "/master/blok-mk/new",
    component: () => import("@/pages/masters/blocks/New.vue"),
  },
  {
    path: "/master/blok-mk/update/:id",
    component: () => import("@/pages/masters/blocks/Update.vue"),
  },
  //bidang ilmu
  {
    path: "/master/bidang-ilmu",
    component: () => import("@/pages/masters/disciplines/List.vue"),
  },
  {
    path: "/master/bidang-ilmu/new",
    component: () => import("@/pages/masters/disciplines/New.vue"),
  },
  {
    path: "/master/bidang-ilmu/update/:id",
    component: () => import("@/pages/masters/disciplines/Update.vue"),
  },
  //topik
  {
    path: "/master/topik",
    component: () => import("@/pages/masters/topics/List.vue"),
  },
  {
    path: "/master/topik/new",
    component: () => import("@/pages/masters/topics/New.vue"),
  },
  {
    path: "/master/topik/update/:id",
    component: () => import("@/pages/masters/topics/Update.vue"),
  },
  //tinjauan
  {
    path: "/master/tinjauan",
    component: () => import("@/pages/masters/reviews/List.vue"),
  },
  {
    path: "/master/tinjauan/new",
    component: () => import("@/pages/masters/reviews/New.vue"),
  },
  {
    path: "/master/tinjauan/update/:id",
    component: () => import("@/pages/masters/reviews/Update.vue"),
  },
  {
    path: "/master/sub-tinjauan",
    component: () => import("@/pages/masters/sub-reviews/List.vue"),
  },
  {
    path: "/master/sub-tinjauan/new",
    component: () => import("@/pages/masters/sub-reviews/New.vue"),
  },
  {
    path: "/master/sub-tinjauan/update/:id",
    component: () => import("@/pages/masters/sub-reviews/Update.vue"),
  },
];
