import Vue from "vue";
import VueRouter from "vue-router";
import { jwtDecode } from "jwt-decode";

Vue.use(VueRouter);

const routeModules = require.context("./groups", true, /routes\.js$/);
const routes = [];

routeModules.keys().forEach((fileName) => {
  const routeModule = routeModules(fileName).default;
  routes.push(...routeModule);
});

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const userData = localStorage.getItem("userData");
  let userLevel = null;

  if (userData) {
    const userAceess = JSON.parse(userData);
    const decodedToken = jwtDecode(userAceess.token);
    userLevel = decodedToken ? decodedToken.role : null;
  }

  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !userData) {
    next("/login");
  } else if (to.path === "/login" && userData) {
    next("/dashboard");
  } else if (requiredLevel && userLevel !== requiredLevel) {
    next("/forbidden");
  } else {
    next();
  }
});

export default router;
