export default [
  {
    path: "/akademisi/mahasiswa",
    component: () => import("@/pages/collegers/List.vue"),
  },
  {
    path: "/akademisi/mahasiswa/create",
    component: () => import("@/pages/collegers/Form.vue"),
  },
  {
    path: "/akademisi/dosen",
    component: () => import("@/pages/lecturers/List.vue"),
  },
];
