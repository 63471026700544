export default [
  {
    path: "/paket-soal/list-paket",
    component: () => import("@/pages/question-packages/List.vue"),
  },
  {
    path: "/paket-soal/list-paket/update/:id",
    component: () => import("@/pages/question-packages/Update.vue"),
  },
  {
    path: "/paket-soal/list-paket/detail/:id",
    component: () => import("@/pages/question-packages/Detail.vue"),
  },
  {
    path: "/paket-soal/buat-paket",
    component: () => import("@/pages/question-packages/Form.vue"),
  },
];
